<template>
  <div class="event-detail"  :class="{ 'screen-shot': isPrintScreen }">
    <div class="event-detail-content">
      <template>
        <div v-if="!isBackPlay" class="snapshot">
          <EventSnapshotOr v-if="type === 'or'" :event="event" :isPrintScreen="isPrintScreen"/>
          <EventSnapshot v-else :event="event" :type="type" />
        </div>
        <div v-else class="video">
          <EventVideo :event="event" :type="type" />
        </div>
      </template>
      <div class="event-info">
        <EventInfoVideo
          v-if="type === 'video' && videoEvent"  
          :event="videoEvent"
        />
        <EventInfoSos
          v-else-if="type === 'sos'"  
          :event="event"
        />
        <EventInfo v-else-if="type === 'lpr' || type === 'chased'"  :event="event" />
        <EventInfoOr v-else-if="type === 'or'" :event="event" :isPrintScreen="isPrintScreen" />
      </div>
    </div>
    <div class="event-detail-map" :class="{ 'no-map': !hasGps }">
      <EventMap 
        v-if="hasGps" 
        :event="type === 'video' ? gpsList[0] : event" 
        :type="type" :showVideoPath="true" 
      />
      <img v-else src="@/assets/icons/Map.svg" alt="">
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  name: 'EventDetail',
  components: {
    EventSnapshot: () => import('@/components/Base/EventSnapshot.vue'), 
    EventSnapshotOr: () => import('@/components/Base/EventSnapshotOr.vue'),
    EventVideo: () => import('@/components/Base/EventVideo.vue'), 
    EventMap: () => import('@/components/Base/EventMap.vue'),
    EventInfo: () => import('@/components/Base/EventInfo.vue'),
    EventInfoOr: () => import('@/components/Base/EventInfoOr.vue'),
    EventInfoSos: () => import('@/components/Base/EventInfoSos.vue'),
    EventInfoVideo: () => import('@/components/Base/EventInfoVideo.vue'),
  },
  props: {
    event: {
      type: Object
    },
    type: {
      type: String,
      default: 'lpr', // lpr, or, chased, sos, video
    },
    isPrintScreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isPlayVideo: false,
    }
  },
  computed: {
    ...mapState('historyVideo', ['videoGpsMap']),
    ...mapState('video', ['total', 'isBackPlay', 'videoList', 'videoIndex']),
    /* 歷史影片的GPS位置是要另外呼叫API取得的 */
    gpsList() {
      return this.videoGpsMap[this.event.id] || []
    },
    videoEvent() {
      if (this.videoList.length > 0 && this.videoIndex >= 0)
        return this.videoList[this.videoIndex]
      return null
    },
    orEventHasGps() {
      return this.event.latitude > 0 && this.event.longitude > 0
    },
    hasGps() {
      return this.gpsList.length > 0 || this.orEventHasGps || this.event.gps
    },
  },
  watch: {
    'event.id'() {
      this.onInitEventVideos() // 取得該事件的 video, gps
      if (this.type === 'or') {
        this.$store.dispatch('historyOr/getObjsClassCount', this.event.objects)
        this.$store.dispatch('historyOr/getDetectAreas', this.event.metadata)
      }
      this.isPlayVideo = false
      this.updateIsBackPlay(false)
    },
  },
  created() {
    this.updateIsBackPlay(false)
    if (this.isPrintScreen) return // 截圖時不需要再次擷取影片
    this.onInitEventVideos() // 取得該事件 video, gps
    if (this.type === 'or') {
      this.$store.dispatch('historyOr/getObjsClassCount', this.event.objects)
      this.$store.dispatch('historyOr/getDetectAreas', this.event.metadata)
    }
  },
  beforeDestroy() {
    this.closeVideo()
    if (this.isPrintScreen) return // 截圖時不需要重置 video store
    this.$store.commit('video/resetState')
  },
  methods: {
    ...mapMutations('video', [
      'updateIsBackPlay', 
      'updateEventVideoList'
    ]),
    ...mapActions('video', ['initEventVideos']),
    async onInitEventVideos() {
      // chased = 0 (非追車事件) / chased = 2 (正在追車事件) / chased = 3 (已完成追車事件)
      if (!this.event || (this.type === 'lpr' && this.event.chased === 0)) return

      const payload = {}
      
      if (this.type === 'sos') {
        payload.postId = [this.event.userAccount]
        payload.startTime = this.event.startTime
        payload.stopTime = this.event.endTime
        payload.eventType = 'sos'
      } else if (this.type === 'chased' || (this.type === 'lpr' && this.event.chased > 0)) { 
        // chased = 2 (正在追車, 沒有chasingEndTime) / chased = 3 (追車事件)
        payload.postId = [this.event.user.id]
        payload.startTime = this.event.chasingStartTime
        payload.stopTime = this.event.chasingEndTime || new Date().toISOString()
        payload.eventType = 'chased'
      } else if (this.type === 'video') {
        this.updateEventVideoList([this.event])

        payload.postId = [this.event.user.id]
        payload.startTime = this.event.startTime
        payload.stopTime = this.event.stopTime
        payload.eventType = 'video'
      } else if (this.type === 'or') {
        payload.postId = [this.event.user.id]
        payload.startTime = this.event.detectTime
        payload.stopTime = this.event.detectTime
        payload.eventType = 'or'
      }
      
      await this.initEventVideos(payload)
    },
    handlePlayVideo() {
      this.isPlayVideo = true
      this.updateIsBackPlay(true)
    },
    closeVideo() {
      this.isPlayVideo = false
      this.updateIsBackPlay(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.event-detail {
  width: 100%;
  height: 100%;  
  display: flex;
  column-gap: 20px;

  &.screen-shot {
    height: auto;
    .event-detail-content {
      .snapshot {
        width: 640px;
        height: 360px;
      }
      .event-info {
        width: 640px;
      }
    }

    .event-detail-map {
      width: 480px;
      height: 600px;
    }
  }
}

.event-detail-content {
  width: 56%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.event-detail-map {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44%;
  height: 100%;
  &.no-map {
    outline: 1px solid #4A5C7880;
  }

  img {
    width: 30%;
    @include filter_FFF_20;
  }
}

.snapshot, .video {
  position: relative;
  width: 100%;
  min-height: 50%;
  max-height: 50%;
  margin-bottom: 14px;
  .icon {
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 48px;
    height: 48px;
    background-color: #19191933;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 28px;
      height: 28px;
    }
    &:hover {
      background-color: #19191980;
    }
  }

  .close {
    top: 8px;
    opacity: 0;
    img {
      width: 20px;
      height: 20px;
    }
  } 

  &:hover {
    .close {
      opacity: 1;
      background: #ffffff1a;
    }
  }
}

.event-info {
  width: 100%;
  height: 50%;
  overflow: scroll;
}
</style>